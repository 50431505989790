import React, { useEffect, useRef, useState } from "react";
import classNames from "lib/classNames";
import NodeBannerTeaser, {
  NodeBannerTeaserImage,
} from "components/Banner/NodeBannerTeaser";
import useIsInViewport from "hook/useIsInViewport";

export default function NodeBannerList({ nodes }) {
  const [active, setActive] = useState(nodes[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  const [firstIndex, lastIndex] = [0, nodes.length - 1];
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  const prev = () => {
    const prevIndex = activeIndex === firstIndex ? lastIndex : activeIndex - 1;
    setActiveIndex(prevIndex);
    setActive(nodes[prevIndex]);
  };

  const next = () => {
    const nextIndex = activeIndex === lastIndex ? firstIndex : activeIndex + 1;
    setActiveIndex(nextIndex);
    setActive(nodes[nextIndex]);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <section
      ref={ref}
      className="relative aspect-w-7 aspect-h-9 sm:pb-0 sm:h-full overflow-hidden bg-primary-dark"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <NodeBannerTeaserImage key={active.id} node={active} />
      <div
        className="hidden sm:block absolute inset-0 bg-gradient-to-t from-default/30 via-transparent"
        aria-hidden="true"
      />
      <div
        className="hidden sm:block absolute inset-0 bg-gradient-to-r from-primary via-transparent"
        aria-hidden="true"
      />
      <div className="hidden sm:block relative container mx-auto px-4 2xl:py-14 h-full">
        <div className="flex flex-col h-full">
          <NodeBannerTeaser key={active.id} node={active} />
          {nodes.length > 1 && (
            <ul
              className="flex md:!translate-x-0 transition-transform"
              style={{
                transform: `translateX(${activeIndex * -75}vw)`,
              }}
            >
              {nodes.map((node, index) => {
                const isActive = node.id === active.id;
                const thisTransitionDelay = transitionDelay * index;

                return (
                  <li
                    key={node.id}
                    className="flex-[0_0_75vw] md:flex-1 transition-all"
                    style={{
                      opacity: isInViewport ? 100 : 0,
                      transitionDuration: `${transitionDuration}s`,
                      transitionDelay: `${thisTransitionDelay}s`,
                    }}
                  >
                    <button
                      type="button"
                      className={classNames(
                        "w-full px-2 py-4 md:py-6 text-left text-lg 2xl:text-xl font-important text-white transition-all duration-150 delay-75",
                        isActive
                          ? "border-t-2 border-white text-shadow-bold"
                          : "border-t border-white/50 hover:border-t-2 hover:border-white"
                      )}
                      onClick={() => {
                        setActiveIndex(index);
                        setActive(node);
                      }}
                    >
                      <span
                        className="block transition-all"
                        style={{
                          transform: `translateY(${
                            isInViewport ? 0 : "1.5rem"
                          })`,
                          transitionDuration: `${transitionDuration}s`,
                          transitionDelay: `${thisTransitionDelay}s`,
                        }}
                      >
                        {node.title}
                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
}
