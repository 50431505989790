import React from "react";

export default function BottomLeftShape({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1000"
      height="690"
      viewBox="0 0 1000 690"
      className={className}
    >
      <path
        fill="currentColor"
        d="M9.69 688.97V414.6l165.27 91.46 72.63 40.19v142.94h9.69V546.25l72.63-40.19 165.27-91.46v274.37l-.41.22h19.12L747.6 559.85l233.71 129.34H1000V405.83c0-1.79-.99-3.43-2.57-4.28L749.86 269.69a4.846 4.846 0 0 0-4.56 0L504.84 397.75V134.82c0-1.81-1-3.46-2.6-4.29L254.66 1.36c-.7-.37-1.47-.56-2.24-.56-.87 0-1.74.24-2.51.71a4.84 4.84 0 0 0-2.33 4.14v260.87L2.5 402.15l-.04.04-.07.02c-.51.3-.84.77-1.2 1.21-.16.19-.4.32-.52.53-.43.73-.67 1.56-.67 2.43v282.81h10.09l-.4-.22zm742.73-406.94 237.89 126.7V683.1l-27.84-15.41-210.05-116.23V282.03zM504.84 408.74l237.89-126.7v269.42L532.69 667.7l-27.84 15.41V408.74zM257.26 13.65l237.89 124.1v260.41L329.32 306.4l-72.06-39.88V13.65zm-4.84 261.26L490 406.38 252.42 537.87 14.85 406.38l237.57-131.47z"
      />
    </svg>
  );
}
