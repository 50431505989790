import React, { useRef } from "react";
import Button from "components/Button";
import { ArrowRightIcon } from "@heroicons/react/outline";
import useIsInViewport from "hook/useIsInViewport";
import Image from "next/image";
import NextLink from "components/NextLink";

export default function NodeBannerTeaser({ node }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    <div
      ref={ref}
      className="flex-1 flex flex-col justify-center gap-12 py-20 2xl:py-0"
    >
      <h2
        className="text-white font-important text-4xl md:text-6xl 2xl:text-7xl md:w-1/2 transition-all"
        style={{
          opacity: isInViewport ? 100 : 0,
          transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
          transitionDuration: `${transitionDuration}s`,
          transitionDelay: `${transitionDelay}s`,
        }}
      >
        {node.title}
      </h2>

      {node.field_link && (
        <div
          className="transition-all"
          style={{
            opacity: isInViewport ? 100 : 0,
            transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
            transitionDuration: `${transitionDuration}s`,
            transitionDelay: `${transitionDelay + 0.2}s`,
          }}
        >
          <Button as={NextLink} href={node.field_link.url || "#"} inverse>
            <span className="flex items-center gap-4">
              <span>{node.field_link.title}</span>
              <ArrowRightIcon
                className="h-5 w-5 text-primary group-hover:text-white"
                aria-hidden="true"
              />
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}

export function NodeBannerTeaserImage({ node }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 1;
  const transitionDelay = 0;

  const smImageField = node.field_sm_image ? "field_sm_image" : "field_image";

  return (
    <div
      ref={ref}
      className="transition-all"
      style={{
        opacity: isInViewport ? 100 : 0,
        transitionDuration: `${transitionDuration}s`,
        transitionDelay: `${transitionDelay}s`,
      }}
    >
      <Image
        src={node.field_image.image_style_uri.hq}
        {...node.field_image.resourceIdObjMeta}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        className="!hidden sm:!block"
        quality={100}
        priority
      />
      <Image
        src={node[smImageField].image_style_uri.hq_mobile}
        {...node[smImageField].resourceIdObjMeta}
        layout="fill"
        objectFit="cover"
        objectPosition="left"
        className="!block sm:!hidden"
        quality={100}
        priority
      />
    </div>
  );
}
