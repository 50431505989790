import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import i18nConfig from "i18n.json";

export default function Meta({
  title,
  description,
  image,
  useTemplate = false,
}) {
  const { lang, t } = useTranslation("common");
  const { asPath } = useRouter();
  const { locales, domains, localizedTypes } = i18nConfig;
  const [first, second] = asPath.slice(1).split("/");
  const type = locales.includes(first) ? second : first;
  const localizedType = localizedTypes[type];
  const config = domains.filter(
    ({ defaultLocale }) => defaultLocale === lang
  )[0];

  const canonical = new URL(
    localizedType ? asPath.replace(type, localizedType[lang]) : asPath,
    `https://${config.domain}`
  );

  const siteTitle = title
    ? useTemplate
      ? t("meta.title_full", { title: title.replace(/(<([^>]+)>)/gi, "") })
      : title.replace(/(<([^>]+)>)/gi, "")
    : t("meta.title");
  const siteDescription = description
    ? description.replace(/(<([^>]+)>)/gi, "")
    : t("meta.description");

  return (
    <Head>
      <title>{siteTitle}</title>
      <meta key="title" name="title" content={siteTitle}/>
      <meta key="description" name="description" content={siteDescription}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={canonical.toString()}/>
      <meta key="og:title" property="og:title" content={siteTitle}/>
      <meta
        key="og:description"
        property="og:description"
        content={siteDescription}
      />
        {image ? (
          <meta key="og:image" property="og:image" content={image}/>
        ) : (
          <>
            <meta property="og:image" content="/images/placeholder.jpg"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="627"/>
          </>
        )}
      <meta name="msapplication-TileColor" content="#5388E6"/>
      <meta name="theme-color" content="#FFFFFF"/>
      <meta
        name="google-site-verification"
        content="SNfkxq3O_bxOthLSjGCSgRwVeBcTqq0Yt5WfqesgN1Y"
      />
      <link rel="canonical" href={canonical.toString()}/>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json"/>
    </Head>
  );
}
