import React from "react";
import parse, { domToReact, Element } from "html-react-parser";
import Image from "next/image";
import classNames from "lib/classNames";
import QuoteIcon from "components/Icons/QuoteIcon";
import NextLink from "components/NextLink";
import BottomLeftShape from "./Shapes/BottomLeftShape";

const options = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.name === "img") {
      const { src, alt, width, height } = domNode.attribs;

      return (
        <Image
          src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
          width={`${width}px`}
          height={`${height}px`}
          alt={alt}
          layout="intrinsic"
          objectFit="cover"
          quality={100}
        />
      );
    }

    if (domNode.name === "blockquote") {
      const { class: className } = domNode.attribs;

      return (
        <blockquote
          className={classNames("relative prose prose-white", className)}
        >
          <QuoteIcon className="h-10 w-10" />
          {domToReact(domNode.children)}
          <div className="absolute bottom-0 right-0" aria-hidden="true">
            <BottomLeftShape className="w-36 h-auto text-white/30" />
          </div>
        </blockquote>
      );
    }

    if (domNode.name === "a") {
      const { href, class: className, ...rest } = domNode.attribs;

      return (
        <NextLink href={href || "#"} className={className} {...rest}>
          {domToReact(domNode.children)}
        </NextLink>
      );
    }

    return domNode;
  },
};

export default function Body({ value }) {
  return <>{parse(value, options)}</>;
}
