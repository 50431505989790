import React from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import classNames from "lib/classNames";

export default function BrandingContent({ home = false, size = "default" }) {
  const { t } = useTranslation("common");
  const Tag = home ? "div" : "a";
  const tagProps = home ? {} : { href: "/" };
  const TitleTag = home ? "h1" : "div";
  const sizes = {
    default: "w-[60px] sm:w-[208px] h-[60px]",
    small: "w-[45px] sm:w-[156px] h-[45px]",
  };

  return (
    <Tag
      className={classNames(
        "inline-block no-underline transition-all",
        sizes[size]
      )}
      {...tagProps}
    >
      <div className="hidden sm:block">
        <Image
          src="/images/logo.png"
          width="208"
          height="60"
          layout="responsive"
          alt={t("branding.alt")}
          quality={100}
          priority
        />
      </div>
      <div className="block sm:hidden">
        <Image
          src="/images/sigil.png"
          width="61"
          height="67"
          layout="responsive"
          alt={t("branding.alt")}
          quality={100}
          priority
        />
      </div>
      <TitleTag className="sr-only">
        {t("branding.title.part1")}
        {t("branding.title.part2")}
      </TitleTag>
    </Tag>
  );
}
